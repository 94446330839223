import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { GeneralService } from 'services/general.service';
@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styleUrls: ['./perfil-usuario.component.css']
})
export class PerfilUsuarioComponent implements OnInit {
  cargando = false;
  casos;
  selectedCaso;
  stop = false;
  constructor(public servicio: GeneralService,public ruta : Router) {
    this.servicio.getAllDocumentsConditional("t_caso", "cliente", "==", servicio.selectedUser.usuario_id).subscribe(casos => {
 
      casos.forEach((caso: any) => {
        this.selectedCaso = caso;
        if (caso.hilo)
          caso.hilo.forEach((nudo: any) => {
            nudo.fecha = nudo.fecha.toDate() as Date;
          });
      });
      this.casos = casos;
    });
  }
  cambio() {
    
    this.stop = false;
  }
  salir(){
    this.servicio.selectedUser = undefined;
    this.ruta.navigateByUrl("index/main");
  }
  scrollBottom() {
    if (!this.stop) {
      setTimeout(function () { $('#historia').scrollTop($('#historia').prop('scrollHeight'));  }, 300);
      document.getElementById("historia1").style.display = "block";
      this.cargando = false;
      this.stop = true; 
    }
  }
  ngOnInit() {
  }

}
