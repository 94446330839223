import { GeneralService } from 'services/general.service';

export class Consulta {
    private consulta;
    private pregunta;
    private respuesta;
    private preguntas: any[];
    private servicio: GeneralService;
    private type: string;
    constructor(generalService: GeneralService) {
        this.servicio = generalService;
        this.preguntas = [];
    }
    public getType() {
        return this.type;
    }
    public setType(type: string) {
        this.type = type;
    }
    public getConsulta() {
        return this.consulta;
    }
    public setConsulta(consulta) {
        this.consulta = consulta;
        this.setPreguntas();
    }
    public getPregunta() {
        return this.pregunta;
    }
    public setPregunta(pregunta) {
        this.pregunta = pregunta;
        if (this.pregunta != null)
            this.setRespuesta();
        else
            this.respuesta = null;
    }
    private setRespuesta() {
        this.servicio.getAllDocuments('t_respuestas').subscribe(
            respuestas => {
                respuestas.forEach((element:any) => {
                    if (element.respuesta_id == this.pregunta.respuesta_id) {
                        this.respuesta = element;
                    }
                });
            }
        );
    }
    public getRespuesta() {
        return this.respuesta;
    }
    private setPreguntas() {
        this.servicio.getAllDocuments('t_preguntas').subscribe(
            preguntas => {
                preguntas.forEach((element:any) => {
                    if (element.consulta == this.consulta.id)
                        this.preguntas.push(element);
                });
            }
        );
    }
}
