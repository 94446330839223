import { Injectable } from '@angular/core';
import { GeneralService } from 'services/general.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  selectedUser;
  error = "";
  constructor(public servicio: GeneralService) {

  }

  
}
