import { Component, Input } from '@angular/core';
import { MenuService } from '@core';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
})
export class SidemenuComponent {
  // NOTE: Ripple effect make page flashing on mobile
  @Input() ripple = true;

  menus = this.menuService.getAll();

  constructor(private menuService: MenuService) {
    console.log(this.menus);
    this.menuService.add({ name : "Solicitudes" , state : "solicitudes", type : "link", icon : "recent_actors" })
  }

  // Delete empty value in array
  filterStates(states: string[]) {
    return states.filter(item => item && item.trim());
  }
}
