// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  SERVER_URL: '',
  production: false,
  useHash: true,
  hmr: false,
};

export const environmentFirebase = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCNFf1fVmW1aNq9cdY4pLIQEayf5CP8_CY",
    authDomain: "lawyer-edfdc.firebaseapp.com",
    databaseURL: "https://lawyer-edfdc.firebaseio.com",
    projectId: "lawyer-edfdc",
    storageBucket: "lawyer-edfdc.appspot.com",
    messagingSenderId: "351846986194",
    appId: "1:351846986194:web:8f4a043dd9aef687a6d80b",
    measurementId: "G-CL8CNTZ2RL"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
