import { Component } from '@angular/core';
import { GeneralService } from 'services/general.service';

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.html',
})
export class UserPanelComponent {

  constructor(public servicio : GeneralService){}
  salir(){
    this.servicio.selectedUser = undefined;
  }
}
