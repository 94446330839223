import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboDwComponent } from './combo-dw/combo-dw.component';
import { Seccion1Component } from './seccion1/seccion1.component';
import { Seccion2Component } from './seccion2/seccion2.component';
import { Seccion3Component } from './seccion3/seccion3.component';
import { SeccionNosotrosComponent } from './seccion-nosotros/seccion-nosotros.component';
import { Seccio4Component } from './seccio4/seccio4.component';



@NgModule({
  declarations: [ComboDwComponent, Seccion1Component, Seccion2Component, Seccion3Component, SeccionNosotrosComponent, Seccio4Component],
  exports:[Seccion1Component,Seccion2Component, ComboDwComponent, Seccion3Component,Seccio4Component, SeccionNosotrosComponent ],
  imports:[CommonModule]
})
export class MainModule { }
