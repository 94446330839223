import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RoutesRoutingModule } from './routes-routing.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { RegisterComponent } from './sessions/register/register.component';
import { HelpersComponent } from './helpers/helpers.component';
import { LandingComponent } from './landing/landing.component';
import { MenuComponent } from './landing/menu/menu.component';
import { MainComponent } from './main/main.component';
import { MainModule } from './main/main.module';
import { PersonasComponent } from './personas/personas.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { ComboDw2Component } from './combo-dw2/combo-dw2.component';
import { FooterComponent } from './landing/footer/footer.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { SeccionPersonalComponent } from './nosotros/seccio-personal/seccio-personal.component';
import { BlogComponent } from './blog/blog.component';
import { CrearArticuloComponent } from './crear-articulo/crear-articulo.component';
import { FormsModule,ReactiveFormsModule, } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PerfilUsuarioComponent } from './perfil-usuario/perfil-usuario.component';
import { CasosComponent } from './casos/casos.component';
import { AddCasosComponent } from './add-casos/add-casos.component';
import { LoginComponent } from './login/login.component';
import { ConsultaComponent } from './consulta/consulta.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { RegistroComponent } from './registro/registro.component';

const COMPONENTS = [DashboardComponent, LoginComponent, RegisterComponent, HelpersComponent, LandingComponent ];
const COMPONENTS_DYNAMIC = [];

@NgModule({
  imports: [SharedModule,  NgxCaptchaModule,RoutesRoutingModule,MainModule,FormsModule,CommonModule,  ReactiveFormsModule   ],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC,FooterComponent, MenuComponent, MainComponent, PersonasComponent, EmpresaComponent, ComboDw2Component, NosotrosComponent, SeccionPersonalComponent, BlogComponent, CrearArticuloComponent, PerfilUsuarioComponent, CasosComponent, AddCasosComponent, LoginComponent, ConsultaComponent, RegistroComponent],
  entryComponents: COMPONENTS_DYNAMIC,
})
export class RoutesModule {}
