import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { map } from 'rxjs/operators';
import { Consulta } from '../app/models/consulta';
import { Articulo } from 'app/models/articulo';


@Injectable()

export class GeneralService {

  articulo = { art: '#topBlog', ir: false }
  consulta: Consulta;
  articulos = [];
  selectedArticulo;
  respuesta = {
    pregunta: '',
    respuesta: ''
  }
  usuarios;
  selectedUser;
  error = {
    mensaje: "",
    code: -1
  };
  constructor(
    public angularFirestore: AngularFirestore,
    public storage: AngularFireStorage
  ) {
    this.consulta = new Consulta(this);
    this.agregarArticulos();
    this.getAllDocuments("t_usuario").subscribe(usuarios => {
      this.usuarios =  usuarios;
      console.log(this.usuarios);
    });
    //this.logear("algo@gmail.com","123456");

  }
  logear(email, contraseña) {
    return this.getAllDocumentsConditional("t_usuario", "email", "==", email);
  }
  async agregarArticulos() {
    this.getAllDocuments('articulo').subscribe(
      arts => {
        this.articulos = [];
        var articuloss: any = arts;
        articuloss.forEach(e => {
          var articulo = new Articulo(this);
          articulo.setId(e.id);
          articulo.setTexto(e.texto);
          articulo.setImagen(e.imagen);
          articulo.setEstado(e.estado);
          this.articulos.push(articulo);
        });
      }
    );
  }
  newId() {
    return this.angularFirestore.createId();
  }

  //Tarea para subir archivo
  public tareaCloudStorage(nombreArchivo: string, datos: any) {
    return this.storage.upload(nombreArchivo, datos);
  }

  //Referencia del archivo
  public referenciaCloudStorage(nombreArchivo: string) {
    return this.storage.ref(nombreArchivo);
  }
  public borrarReferencia(nombreArchivo: string) {
    return this.storage.ref(nombreArchivo).delete();
  }
  public getUrl(nombreArchivo: string) {
    try {
      return this.storage.ref(nombreArchivo).getDownloadURL();
    } catch (e) {
      console.log("error: " + e.message);
      return null;
    }
  }

  getAllDocumentsOnlyR(resorce){
    return  new Promise( ( resolve , reject) => {
      this.angularFirestore.collection(resorce).get().subscribe( (a : any ) => {
        var resp = [];
        a.docs.forEach(element => {
            resp.push({ data : element.data(), id : element.id })
        });
        resolve(resp)
      }, error => {
        console.log(error);
        reject(error)
      })
    })
  }

  getAllDocuments(resource) {
    return this.angularFirestore.collection(resource).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }


  getAllDocumentsConditional(resource, a, b, c) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getAllDocumentsConditional2(resource, a, b, c, d, e, f) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(d, e, f)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getAllDocumentsConditional2ByOrder(resource, a, b, c, d, e, f, g) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(d, e, f).orderBy(g)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getAllDocumentsConditionalLimit(resource, a, b, c, d) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).limit(d)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getAllDocumentsConditionalByOrder(resource, a, b, c, d) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).orderBy(d)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getAllDocumentsConditionalByOrder2(resource, a, b, c, d, e) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).orderBy(d).orderBy(e)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getAllDocumentsConditionalByOrder3(resource, a, b, c, d, e, f) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).orderBy(d).orderBy(e).orderBy(f)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getDocument(resource, id) {
    return this.angularFirestore.collection(resource).doc(id).snapshotChanges().pipe(
      map(action => {
        const data = action.payload.data()
        const id = action.payload.id;
        return { id, ...data };
      }));
  }

  editDocument(resource, id, object) {
    return this.angularFirestore.collection(resource).doc(id).set(
      object
    );
  }

  updateDocument(resource, id, object) {
    return this.angularFirestore.collection(resource).doc(id).update(
      object
    );
  }

  newDocument(object, name) {
    console.log(object);
    
    return this.angularFirestore.collection(name).add(
      object
    );
  }

  newDocumentWithId(object, name, id) {
    return this.angularFirestore.collection(name).doc(id).set(
      object
    );
  }

  deleteDocument(name, id) {
    return this.angularFirestore.collection(name).doc(id).delete()
  }
}
