import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'services/general.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-answer-layout',
  templateUrl: './answer-layout.component.html',
  styleUrls: ['./answer-layout.component.scss']
})
export class AnswerLayoutComponent implements OnInit {
  
  art = {
    titulo1:'',
    respuesta1:'',
    titulo2:'',
    respuesta2:'',
    titulo3:'',
    respuesta3:''

  }
  serv: GeneralService;
  router: Router;
  constructor(serv: GeneralService, router: Router) {
    window.scroll(0,0);
    this.serv = serv;
    this.router = router;
  }
  ngOnInit() {
    let a = this.serv.consulta.getPregunta();
    if (this.serv.consulta.getPregunta() == null) {
      console.log('la pregunta no esta cargada');
      this.router.navigate(['#/index/main']);
    }
    else
      this.serv.respuesta.pregunta = this.serv.consulta.getPregunta().pregunta;
    if (this.serv.consulta.getRespuesta() == null)
      console.log('"error de consistencia de la base de datos" - esta pregunta no tiene respuesta asignada');
    else
      this.serv.respuesta.respuesta = this.serv.consulta.getRespuesta().respuesta;
    if( this.serv.consulta.getType() == "Persona" ){
      this.art.titulo2 = 'Que es un Consumidor';
      this.art.respuesta2 = 'Se considera consumidor a la persona física o jurídica que adquiere o utiliza, en forma gratuita u onerosa, bienes o servicios como destinatario final, en beneficio propio o de su grupo familiar o social.';
      this.art.titulo1 = 'Contratos leoninos';
      this.art.respuesta1 = 'Se denominan contratos leoninos a aquellos donde una parte se abusa de las demás, perjudicándolos gravemente. El nombre procede de una fábula de Esopo, que relata que se asociaron para cazar en conjunto, el león con la vaca, la cabra y la oveja, pero una vez obtenido un ciervo como presa, el león se quedó con todas las porciones, argumentando, que era fuerte, poderoso y que, de no aceptar sus condiciones, se los devoraría.';
      this.art.titulo3 = 'Deuda';
      this.art.respuesta3 = 'La deuda es una obligación que tiene una persona física o jurídica para cumplir sus compromisos de pago, fruto del ejercicio de su actividad económica.';
    }
    else{
      this.art.titulo2 = '¿Qué es Fintech?';
      this.art.respuesta2 = 'La palabra Fintech es un término compuesto que viene del inglés y que sale de unir la primera sílaba de las palabrasFinance y Technology, es decir, es una palabra que surge de la unión de dos y que aglutina a todas aquellas empresas de servicios financieros que utilizan la última tecnología existente para poder ofrecer productos y servicios financieros innovadores.';
      this.art.titulo1 = 'Empresas comerciales';
      this.art.respuesta1 = 'Las empresas comerciales son aquellas actividades económicas organizadas que se dedican a la compra y venta de productos que bien pueden ser materias primas o productos terminados, las empresas comerciales cumplen la función de intermediarias entre los productores y los consumidores y no realizan ningún tipo de transformación de materias primas.';
      this.art.titulo3 = 'Entidad financiera';
      this.art.respuesta3 = 'Una entidad financiera es cualquier entidad o agrupación que tiene como objetivo y fin ofrecer servicios de carácter financiero y que van desde la simple intermediación y asesoramiento al mercado de los seguros o créditos bancarios.';
    }
    this.serv.consulta.setPregunta(null);

  }
  setRespuesta(){
    return this.serv.respuesta.respuesta;
  }
  setPregunta(){
    return this.serv.respuesta.pregunta;
  }
}
