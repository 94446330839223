import { Component} from '@angular/core';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})


export class MainComponent {
  

  constructor() {
    var html = "<h4>Solicitud de contacto </h4>" +
    "<h5 > Datos del cliente </h5> <p>Te envio este correo para prueba a ver si te llega al spam o normal </p>";

  var settings = {
    "url": "https://nodemailer-arg2.herokuapp.com/send-email?asd=asd",
    "method": "POST",
    "timeout": 0,
    "data": {
      "email": "jperea@ulp.edu.ar",
      "html": html,
      "asunto": "algo"
    }
  };
/*
  $.ajax(settings).done(function (response) {
    console.log(response);
  });*/
   }

  ngOnInit() {
    
  }
  
}
