import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'services/general.service';
import * as $ from "jquery";
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  serv :GeneralService;
  constructor(serv : GeneralService) { 
    this.serv = serv;
  }

  ngOnInit() {

  }

}
