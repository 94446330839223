import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seccion-nosotros',
  templateUrl: './seccion-nosotros.component.html',
  styleUrls: ['./seccion-nosotros.component.scss']
})
export class SeccionNosotrosComponent implements OnInit {

  constructor() { }

  
  ngOnInit() { }

}
