import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { environment } from '@env/environment';

import { AdminLayoutComponent } from '../theme/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from '../theme/auth-layout/auth-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './sessions/register/register.component';
import { HelpersComponent } from './helpers/helpers.component';
import { LandingComponent } from './landing/landing.component';
import { AnswerLayoutComponent } from 'app/theme/answer-layout/answer-layout.component';
import { AnswerFormsComponent } from './answer-forms/answer-forms.component';
import { MainComponent } from './main/main.component';
import { PersonasComponent } from './personas/personas.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { BlogComponent } from './blog/blog.component';
import { CrearArticuloComponent } from './crear-articulo/crear-articulo.component';
import { PerfilUsuarioComponent } from './perfil-usuario/perfil-usuario.component';
import { CasosComponent } from './casos/casos.component';
import { AddCasosComponent } from './add-casos/add-casos.component';
import { AuthGuard } from 'app/guards/auth.guard';
import { AdminGuard } from 'app/guards/admin.guard';
import { ConsultaComponent } from './consulta/consulta.component';
import { RegistroComponent } from './registro/registro.component';




const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      { path: '', redirectTo: 'users/list', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { title: 'Dashboard', titleI18n: 'dashboard' },
      },

      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
        data: { title: 'Profile', titleI18n: 'Profile' },
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        data: { title: 'Usuarios', titleI18n: 'Usuarios' },
      },
      {
        path: 'solicitudes',
        loadChildren: () => import('./solicitudes/solicitudes.module').then(m => m.SolicitudesModule),
        data: { title: 'Usuarios', titleI18n: 'Usuarios' },
      },
      {
        path: 'preguntas',
        loadChildren: () => import('./preguntas/preguntas.module').then(m => m.PreguntasModule),
        data: { title: 'Preguntas', titleI18n: 'Preguntas' },
      },
      {
        path: 'respuestas',
        loadChildren: () => import('./respuestas/respuestas.module').then(m => m.RespuestasModule),
        data: { title: 'Respuestas', titleI18n: 'Respuestas' },
      },
      {
        path: 'editor',
        component: CrearArticuloComponent
      },{
        path: 'casos',
        component: CasosComponent
      },
      {
        path: 'addCase',
        component: AddCasosComponent
      }

    ],
    canActivate: [AdminGuard]
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'register',
        component: RegisterComponent,
        data: { title: 'Register', titleI18n: 'Register' },
      },
    ],
  },
  {
    path: 'crear-articulo',
    component: CrearArticuloComponent
  },
  {
    path: 'index',
    component: LandingComponent,
    children: [
      {
        path: '', redirectTo: 'main', pathMatch: 'full' 
      },
      {
        path: 'main',
        component: MainComponent
      },
      {
        path: 'consulta',
        component: ConsultaComponent,
      },
      {
        path: 'registro',
        component: RegistroComponent,
      },
      {
        path: 'personas',
        component: PersonasComponent
      },
      {
        path:'empresas',
        component: EmpresaComponent
      },
      {
        path:'nosotros',
        component: NosotrosComponent
      },
      {
        path:'blog',
        component: BlogComponent,
        loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)
      },
      {
        path: 'answer',
        component: AnswerLayoutComponent,
        children: [
          {
            path: 'form',
            loadChildren: () => import('./answer-forms/answer-forms.module').then(m => m.AnswerFormsModule)
          }
        ],
      },
      {
        path: 'perfilUsuario',
        component: PerfilUsuarioComponent,
        canActivate: [AuthGuard]
      },{
        path:"login",
        component: LoginComponent
      }
    ]
  },
  { path: '**', redirectTo: 'main' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
    }),
  ],
  exports: [RouterModule],
})
export class RoutesRoutingModule {
  constructor(
    private router: Router,


  ) {



    this.router.navigate(['/index/main']);

  }
}
