import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'services/general.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-combo-dw',
  templateUrl: './combo-dw.component.html',
  styleUrls: ['./combo-dw.component.scss']
})
export class ComboDwComponent {
  element;nit  
  ruta = "/answer/form/negociar-deuda";
  type: any[] = [
    { value: 'Persona' },
    { value: 'Empresa' },
  ];
  selectedType = 'Quien es';
  selectedConsulta: any;
  selectedPregunta;
  selectedPreguntas: any[];
  selectedConsultas: any[];
  serv: GeneralService;
  preguntas: any[];
  consultasE: any[];
  consultasP: any[];
  cargado= false;
  routes: Router;
  constructor(serv: GeneralService, routes : Router) {
    this.serv = serv;
    this.selectedConsulta = null;
    this.selectedPregunta = '';
    this.selectedPreguntas = [];
    this.selectedConsulta = { consulta: 'Consulta' };
    this.serv.getAllDocuments('t_consultas').subscribe(
      consultas => {
        this.consultasP = [];
        this.consultasE = []; var c = [];
        var c2 = [];
        consultas.forEach((element:any) => {
          c.push(element.consulta);
        });
        c.sort();
        consultas.forEach((element:any) => {
        
          for (let index = 0; index < c.length; index++) {
            if (c[index] == element.consulta)
              c2[index] = element; 
          }
        });
   
        c2.forEach((element:any) => {
          if (element.persona === true)
            this.consultasP.push(element);
          else
            this.consultasE.push(element);
        });
        if (this.selectedType == 'Persona')
          this.selectedConsultas = this.consultasP;
        else
        if (this.selectedType == 'Empresa')
          this.selectedConsultas = this.consultasE;
      });
    this.routes = routes;
  }
  quitar(e:string){
    document.getElementById(e).style.display = "none";
  }
  mostrar(e: string) {
      if(document.getElementById(e).style.display == "flex")
      document.getElementById(e).style.display = "none";
      else
      document.getElementById(e).style.display = "flex";
  }
  prueba(){
    
  }
  comboSelected(a: any, id: String, component: string) {
    switch (id) {
      case ('dropdownPersona'):
        //habilita el dropdown
        this.selectedType = a;
        this.element = document.getElementById('dropdownConsulta');
        this.element.disabled = false;
        // ***
        // carga la lista
        if (a == 'Persona'){
          this.selectedConsultas = this.consultasP;
          this.serv.consulta.setType("Persona");
        }
        else{
          this.selectedConsultas = this.consultasE;
          this.serv.consulta.setType("Empresa");
        }
          // **
        //
        this.selectedPregunta = {};
        this.serv.consulta.setConsulta(null);
        this.selectedConsulta = { consulta: 'Consulta' };
        this.element = document.getElementById('dropdownPregunta');
        this.element.disabled = true;
        break;
      case ('dropdownConsulta'):
        this.selectedConsulta = a;
        this.selectedPreguntas = [];
        this.selectedPregunta = {};
        this.obtenerPreguntas();
        this.ruta = ('index/answer/form/' + this.selectedConsulta.path);
        this.serv.consulta.setConsulta(this.selectedConsulta);
        break;
      case ('dropdownPregunta'):
        this.selectedPregunta = a;
        this.serv.consulta.setPregunta(a);
        break;
    }
    
    this.ruta = ('index/answer/form/' + this.selectedConsulta.path);
    this.serv.consulta.setConsulta(this.selectedConsulta);
    
  }
  redir(){
    this.routes.navigateByUrl(this.ruta);
  }
  obtenerPreguntas() {
    this.serv.getAllDocuments('t_preguntas').subscribe(
      preguntas => {
        preguntas.forEach((pregunta:any) => {
          if (pregunta.consulta === this.selectedConsulta.id && pregunta.estado) {
            this.selectedPreguntas.push(pregunta);
            this.selectedPregunta = pregunta;
            this.serv.consulta.setPregunta(pregunta);
          }
        });
        if(this.selectedPreguntas.length == 0){
          this.element = document.getElementById('dropdownPregunta');
          this.element.disabled = true;
          this.element = document.getElementById('btnConsulta');
          this.element.disabled = true;
        }
        else{
          this.element = document.getElementById('dropdownPregunta');
          this.element.disabled = false;
          this.element = document.getElementById('btnConsulta');
          this.element.disabled = false;
        }
      }
    );
  }
}
