import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReCaptcha2Component } from 'ngx-captcha';
import { GeneralService } from 'services/general.service';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroComponent {
  siteKey: String;
  @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;
  reactiveForm: FormGroup;
  serv: GeneralService;
  cargado = false;
  routes: Router;
  constructor(serv: GeneralService, routes: Router, private fb: FormBuilder) {
    this.siteKey = "6Lcgqx4aAAAAADwUNZUG8e2ncXfs7XDIERUiEy5P";
    this.reactiveForm = this.fb.group({
      nombre: ["", [Validators.required, Validators.minLength(3)]],
      apellido: ["", [Validators.required, Validators.minLength(3)]],
      correo: ["", [Validators.required, Validators.email]],
      recaptcha: ['', [Validators.required]]
    });
    this.serv = serv;
    this.routes = routes;
  }

  goTop() {
    $('html, body').animate({ scrollTop: 0 }, 800);
  }

  quitar(e: string) {
    document.getElementById(e).style.display = "none";
  }
  mostrar(e: string) {
    if (document.getElementById(e).style.display == "flex")
      document.getElementById(e).style.display = "none";
    else
      document.getElementById(e).style.display = "flex";
  }
  prueba() {

  }

  getError(controlName: string): string {
    //console.log(controlName);
    let error = '';

    const control = this.reactiveForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = JSON.stringify(control.errors);
    }

    return error;
  }
  validaNumeros(event) {
    if (event.charCode >= 48 && event.charCode <= 57) {
      return true;
    }
    return false;
  }
  validaNumericos() {
    var inputtxt = document.getElementById('t-telefono') as HTMLInputElement;
    var valor = inputtxt.value;
    for (let i = 0; i < valor.length; i++) {
      var code = valor.charCodeAt(i);
      if (!(code >= 48 && code <= 57)) {
        inputtxt.value = "";
        return;
      }
    }

  }
  enviarCorreo() {
    var save = { nombre :  this.reactiveForm.get("nombre").value  , apellido :  this.reactiveForm.get("apellido").value , correo :  this.reactiveForm.get("correo").value  };
    this.serv.getAllDocumentsOnlyR("t_solicitudRegistro").then((resp: any) => {
      var valid = true;
      
      resp.forEach(element => {
        if (element.data.correo == save.correo) {
          valid = false;
        }
      });
      
      if (valid) {
        this.serv.newDocument( save ,"t_solicitudRegistro").then ( resp => {
          var html = "<h4>Solicitud de registro </h4>" +
          "<h5 > Datos del cliente </h5>" +
          "<ul >" +
          "<li>" +
          "<p>nombre : " + this.reactiveForm.get("nombre").value + " </p>" +
          "</li>" +
          "<li>" +
          "<p>Apellido: " + this.reactiveForm.get("apellido").value + " </p>" +
          "</li>" +
          "<li>" +
          "<p>Correo Electrónico: " + this.reactiveForm.get("correo").value + " </p>" +
          "</li>" +
          "</ul>" +
          "<br>"
    
        var settings = {
          "url": "https://nodemailer-arg2.herokuapp.com/send-email?asd=asd",
          "method": "POST",
          "timeout": 0,
          "data": {
            "email":"gargaglioneabogados@gmail.com",
            "html": html,
            "asunto": "Nuevo registro"
          }
        };
    
        $.ajax(settings).done(function (response) {

        });
          alert("Tu solicitud de registro ha sido enviada");
        })
      } else { alert( "Ya enviaste tu solicitud de registro estamos comprabando los datos pronto tendras una respuesta" ) }
    })


    this.captchaElem.resetCaptcha();
  }
  validacionTelefono() {

  }
  ngAfterContentInit() {
    this.goTop();
  }
}