import { GeneralService } from '../../services/general.service';
import { Seccio4Component } from 'app/routes/main/seccio4/seccio4.component';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
export class Articulo {
    texto = "";
    private titulo = "";
    private imagen = "";
    private servicio;
    private html;
    estado = true;
    contenido = [];
    contenidoT = [];
    contenidoL = [];
    contenidoM = [];
    private id = "";
    textoEdit = new BehaviorSubject<string>(this.texto);
    constructor(servicio: GeneralService) {
        this.servicio = servicio;
        this.estado = true;
        this.textoEdit.subscribe(value => {
            if (value != null) {
                this.texto = value;
                this.formarContenido();
                this.generarContenidoM();
                this.generarContenidoT();
            }
        });
    }

    public static obtenerArticulos(servicio: GeneralService) {
        var articulos = [];
        servicio.getAllDocuments('articulo').subscribe(
            arts => {
                var articuloss: any = arts;
                articuloss.forEach(e => {
                    var articulo = new Articulo(servicio);
                    articulo.setId(e.id);
                    articulo.setTexto(e.texto);
                    articulo.setEstado(e.estado);
                    articulos.push(articulo);
                });
                return articulos;
            }
        );
    }

    public setTexto(texto: string) {
        this.textoEdit.next(texto);
    }
    public setImagen(imagen) {
        this.imagen = imagen;
    }
    public getImagen() {
        return this.imagen;
    }
    public getTexto(): string {
        return this.texto;
    }

    public getHtml() {
        return this.html;
    }

    public getTitulo() {
        return this.titulo;
    }

    public prueba() {
        alert("asda");
    }

    private formarContenido() {
        this.contenido = [];
        this.titulo = "";
        var separado = this.texto.split(/[\n\r]/);
        separado.forEach(element => {
            if ((/^\(T--\)/).test(element)) {
                if (this.titulo === "") {
                    this.titulo = this.quitarDecorador(element);
                    this.contenido.push({
                        tipo: "titulo",
                        texto: this.titulo
                    })
                } else {
                    this.contenido.push({
                        tipo: "subtitulo",
                        texto: this.quitarDecorador(element)
                    })
                }
            } else {
                this.contenido.push({
                    tipo: "parrafo",
                    texto: element
                })
            }
        });
    }

    private quitarDecorador(titulo) {
        var tituloSeparado = titulo.split(/^\(T--\)/);
        return tituloSeparado[1];
    }

    public generarContenidoM() {
        if (this.contenido !== undefined) {
            var contador = 0;
            var i = 0;
            this.contenidoM = [];
            while (contador < 600 && this.contenido[i] != undefined) {
                if (contador + this.contenido[i].texto.length > 600) {
                    var editTexto = JSON.parse(JSON.stringify(this.contenido[i]));
                    editTexto.texto = editTexto.texto.substr(0, 600 - contador) + "...";
                    this.contenidoM.push(editTexto);
                } else {
                    this.contenidoM.push(this.contenido[i]);
                }
                contador += this.contenido[i].texto.length;
                i++;
            }
        }
    }

    public generarContenidoT() {
        if (this.contenido !== undefined) {
            var contador = 0;
            var i = 0;
            this.contenidoT = [];
            while (contador < 120 && this.contenido[i] != null) {
                if (contador + this.contenido[i].texto.length > 120) {
                    var editTexto = JSON.parse(JSON.stringify(this.contenido[i]));
                    editTexto.texto = editTexto.texto.trim();
                    editTexto.texto = editTexto.texto.substr(0, 120 - contador) + "...";
                    this.contenidoT.push(editTexto);
                } else {
                    this.contenidoT.push(this.contenido[i]);
                }
                contador += this.contenido[i].texto.length;
                i++;
            }
        }
    }

    public saveArticulo() {
        var editado;
        var art = {
            texto: this.texto,
            titulo: this.titulo,
            imagen: this.imagen,
            id: this.id,
            estado: this.estado
        }
        console.log(this.id);
        if (this.id == "") {
            this.id = this.servicio.newId();
            art.id = this.id;
            this.servicio.newDocumentWithId(art, 'articulo', art.id).then(
                success => {
                    console.log('articulo guardado');
                    editado = true;
                }
            );
        } else {
            this.servicio.editDocument('articulo', art.id, art).then(
                success => {
                    console.log('articulo editado');
                    editado = false;
                }
            ).catch(error => {
                console.log(error);
            });
        }
        return editado;
    }

    public setEstado(estado: boolean) {
        this.estado = estado;
    }
    public getEstado() {
        return this.estado;
    }
    public getId() {
        return this.id;
    }
    public setId(id) {
        this.id = id;
    }
    public editArticulo() {

    }
    public deshabilitar() {

    }
    
}
