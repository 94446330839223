import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'services/general.service';
@Component({
  selector: 'app-personas',
  templateUrl: './personas.component.html',
  styleUrls: ['./personas.component.scss']
})
export class PersonasComponent implements OnInit {
  tipo ={ t : "Persona"};
  constructor(serv : GeneralService) { 
    serv.consulta.setType("Persona");
  }

  ngOnInit() {
  }

}
