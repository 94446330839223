import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-seccion1',
  templateUrl: './seccion1.component.html',
  styleUrls: ['./seccion1.component.scss']
})
export class Seccion1Component  {

  constructor(private router : Router) { 
    
  }
  nav(){
    console.log("alaf");
    
    this.router.navigateByUrl("/index/consulta");
  }
}
