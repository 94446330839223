import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'services/general.service';

@Component({
  selector: 'app-seccion3',
  templateUrl: './seccion3.component.html',
  styleUrls: ['./seccion3.component.scss']
})
export class Seccion3Component implements OnInit {

  constructor( private router : Router , private general : GeneralService ) { }

  ngOnInit() {

  }

  navToRegister(){
    this.router.navigateByUrl("/index/registro");
    
  }

}
