import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'services/general.service';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss']
})
export class EmpresaComponent implements OnInit {
  tipo ={ t : "Empresa"};
  constructor(serv : GeneralService) { 
    serv.consulta.setType("Empresa");
  }
  ngOnInit() {
  }

}
