import { Component } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.html',
})
export class LandingComponent {
  constructor() { 
   
  }
  ngOninit() {
  }
  goTop(){
    $('html, body').animate( { scrollTop : 0 }, 800 );
  }

}
