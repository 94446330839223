import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'services/general.service';

@Component({
  selector: 'app-add-casos',
  templateUrl: './add-casos.component.html',
  styleUrls: ['./add-casos.component.css']
})
export class AddCasosComponent implements OnInit {
  reactiveForm: FormGroup;
  constructor( private fb: FormBuilder, public service : GeneralService,public ruta:Router ) 
  {
    this.reactiveForm = fb.group({
      nombre: ["", [ Validators.required ] ],
      cliente: [null , [Validators.required]],
    });
   }
   guardar(){
     let caso = {
       nombre: this.reactiveForm.get("nombre").value,
       cliente: this.reactiveForm.get("cliente").value.id,
       id: this.service.newId(),
       recursos: [],
       hilo:[],
       estado: true
     }
     console.log(this.reactiveForm.get("cliente").value.usuario_id + this.reactiveForm.get("nombre").value);
     this.service.newDocumentWithId(caso,"t_caso",caso.id).then( v => {
      this.ruta.navigateByUrl("casos");
     });
   }
  ngOnInit() {
  }

}
