import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'services/general.service';
import { Router } from '@angular/router';
import { AnswerLayoutComponent } from 'app/theme/answer-layout/answer-layout.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  empresa = {
    consultas: '',
    preguntas: []
  }
  empresas: any[];
  rutas: Router;
  serv: GeneralService;
  constructor(serv: GeneralService, rutas: Router ) {
    this.rutas = rutas;
    this.serv = serv;
  }

  irRespuesta(tema: string) {
    let rut;
    this.serv.consulta.setConsulta(tema);
    this.serv.getAllDocuments('t_consultas').subscribe(
      (consultas:any) => {
        consultas.forEach(consulta => {
          if (!consulta.persona && consulta.consulta == tema) {
            rut = 'index/answer/form/' + consulta.path;
            this.rutas.navigateByUrl(rut);
            window.scroll({top: 0});
            this.serv.respuesta.pregunta = 'ponte en contacto con nosotros completando el formulario';
            this.serv.respuesta.respuesta = '';
          }
        });
      }
    );
  }
  ngOnInit( ) {
  }

}
