import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneralService } from 'services/general.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private servicio: GeneralService, private rutas: Router) { }
  canActivate() {
    if (this.servicio.selectedUser) {
      if (this.servicio.selectedUser.email === "abogadosG")
        return true;
      else {
        this.rutas.navigateByUrl("index/login");
        return false;
      }
    }
    else {
      this.rutas.navigateByUrl("index/login");
      return false;
    }
  }
}
