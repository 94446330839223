import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'services/general.service';
import { Router } from '@angular/router';
declare var jsMenu;
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  serv: GeneralService;
  rutas: Router;
  r = "/index/main";
  constructor(serv: GeneralService, rutas: Router) {
    this.serv = serv;
    this.rutas = rutas;
    this.r = this.rutas.url;
  }

  ngOnInit() {
    if (window.location.hash == "#/index/main") {
      if (document.getElementById("imgDis").style.display != "none") {
        document.getElementById("imgDis").style.display = "none";
      }
    }
    window.onscroll = function () {
      if (document.getElementById("nav") != null) {
        if (window.scrollY != 0) {
          document.getElementById("nav").style.background = "#ffff";
          document.getElementById("nav").style.boxShadow = "1px 1px 5px #909497";
          let a = document.getElementById("nav").getElementsByTagName('a');
          document.getElementById("imgDis").style.display = "block";
          Array.prototype.forEach.call(a, function (el) {
            el.style.color = "#272129";
          });

          if (!document.getElementById("nav").classList.contains('active'))
            document.getElementById("nav").classList.toggle('active');

        } else {
          if (window.location.hash == "#/index/main") {
            document.getElementById("imgDis").style.display = "none";
          }
          document.getElementById("nav").style.background = "transparent";
          document.getElementById("nav").style.boxShadow = "none";
          let a = document.getElementById("nav").getElementsByTagName('a');
          Array.prototype.forEach.call(a, function (el) {
            el.style.color = "#ffff";
          });
          /* a = document.getElementsByClassName("sb-menu");
          Array.prototype.forEach.call(a, function (el) {
            let b = el.getElementsByTagName('a')
            Array.prototype.forEach.call(b, function (element) {
              element.style.color = "#272129";
            });
          }); */
          document.getElementById("nav").classList.toggle('active');
        }
      }
    }
    var divs = document.getElementsByClassName("it-menu");
    for (var i = 0; i < divs.length; i++) {
      //Añades un evento a cada elemento
      divs[i].addEventListener("click", function () {
        var width = window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        if (width < 900) {
          if (document.getElementById("menu").style.height == "290px") {

            document.getElementById("menu").style.height = "0px";
            document.getElementById("menu").style.paddingTop = "0px";
          } else {

            document.getElementById("menu").style.paddingTop = "20px";
            document.getElementById("menu").style.height = "290px";
          }
        }
        window.scroll({
          top: 0
        });
      });
    }

    window.onresize = function () {
      if(  document.getElementById("menu") != null){
      var width = window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width > 900) {
        document.getElementById("menu").style.display = "flex";
        document.getElementById("menu").style.height = "100%";
      } else {
        document.getElementById("menu").style.display = "block";
        document.getElementById("menu").style.height = "0px";
        document.getElementById("menu").style.paddingTop = "0px";
      }
    };
  }
  }

  mostrarMenuAct() {
    var width = window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (width > 900) {
      if (document.getElementById("contenido").classList.contains('activeMenu2')) {
        document.getElementById("menu-2").style.background = "transparent";
        document.getElementById("contenido").classList.toggle('activeMenu3');
        document.getElementById("contenido").classList.toggle('activeMenu2');
        document.getElementById("menu-2").style.zIndex = "-1";
        //    box-shadow: 1px 1px 5px #909497;
      } else {

        document.getElementById("menu-2").style.zIndex = "1000";
        document.getElementById("menu-2").style.background = "rgba(0, 0, 0, 0.733)";
        document.getElementById("contenido").classList.toggle('activeMenu2');
        document.getElementById("contenido").classList.toggle('activeMenu3');
      }
    } else {
      if (document.getElementById("menu").style.height == "290px") {

        document.getElementById("menu").style.height = "0px";
        document.getElementById("menu").style.paddingTop = "0px";
      } else {

        document.getElementById("menu").style.paddingTop = "20px";
        document.getElementById("menu").style.height = "290px";
      }
    }
  };
  GoToBottom() {
    $('html, body').animate({ scrollTop: $("#footer").offset().top }, 800);
  }
  resetearPregunta(ruta: string) {
    this.serv.respuesta.pregunta = '';
    this.serv.respuesta.respuesta = '';
    this.ruta(ruta);
  }
  ruta(ruta: string) {
    this.r = this.rutas.url;
    if (ruta == "/index/main") {
      document.getElementById("imgDis").style.display = "none";
      document.getElementById("c2").style.color = "black";
    } else {
      document.getElementById("imgDis").style.display = "block";
    }
  }
}
