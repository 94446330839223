import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'services/general.service';
import { Articulo } from 'app/models/articulo';

@Component({
  selector: 'app-seccio4',
  templateUrl: './seccio4.component.html',
  styleUrls: ['./seccio4.component.scss']
})
export class Seccio4Component implements OnInit {

  rutas : Router;
  serv : GeneralService;
  
  constructor(rutas : Router, serv :GeneralService) { 
    this.rutas = rutas;
    this.serv = serv;
  }

  ngOnInit() {
    
  }

  ir(art){
    this.serv.selectedArticulo = art;
    window.scroll({
      top: 0
    });
  }

  goToArticulo(articulo){
    this.serv.articulo.art = '#'+ articulo;
    this.serv.articulo.ir = true;
  }
}
