import { Component, OnInit } from '@angular/core';
import { Articulo } from 'app/models/articulo';
import { FormControl } from '@angular/forms';
import { GeneralService } from 'services/general.service';
import { finalize, delay } from 'rxjs/operators';
import { Observable, Subject, of, pipe } from 'rxjs';

@Component({
  selector: 'app-crear-articulo',
  templateUrl: './crear-articulo.component.html',
  styleUrls: ['./crear-articulo.component.scss']
})
export class CrearArticuloComponent implements OnInit {
  selectedArticulo: Articulo;
  articulos = [];
  crearTitulo = "";
  titulos = [];
  servicio;
  seleccion = "tarjeta";
  most = false;
  uploadImage: Observable<number>;
  ref = null;
  carga = 0;
  imagen;
  cargado = false;
  origin;
  constructor(serv: GeneralService) {
    this.servicio = serv;
    this.selectedArticulo = new Articulo(serv);
    this.articulos = serv.articulos;

  }
  cambiarEstado() {
    if (this.selectedArticulo.estado) {
      this.selectedArticulo.estado = false;
    } else {
      this.selectedArticulo.estado = true;
    }
  }
  descartar() {
    this.selectedArticulo = new Articulo(this.servicio);
    this.ref = null;
    this.imagen = null;
  }
  eliminar() {
    this.servicio.deleteDocument('articulo', this.selectedArticulo.getId()).then(
      success => {
        console.log('articulo borrado');
        this.selectedArticulo = new Articulo(this.servicio);
    this.ref = null;
    this.imagen = null;
      });
    this.servicio.referenciaCloudStorage(this.selectedArticulo.getImagen()).delete();
  }
  seleccionarArticulo(articulo: Articulo) {

    this.imagen = null;
    this.selectedArticulo = this.copiar(articulo);
    try {
      this.servicio.getUrl(this.selectedArticulo.getImagen()).subscribe(value => {
        this.ref = value;
      });
    } catch (err) {
      console.log("la ruta que no coincide: " + this.selectedArticulo.getImagen())
      this.ref = null;
    }
  }
  copiar(orig) {
    var articulo = new Articulo(this.servicio);
    articulo.setId(orig.getId());
    articulo.setTexto(orig.texto);
    articulo.setImagen(orig.getImagen());
    articulo.setEstado(orig.estado);
    return articulo;
  }
  ngOnInit() {
  }
  upFile(e) {

    var reader = new FileReader();

    if (e.target.files && e.target.files[0]) {
      var reader = new FileReader();

      this.imagen = e.target.files[0];

      reader.onload = (event: any) => {
        this.ref = event.target.result;
      }

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  info() {
    alert("utilice el decorador (T--) al inicio de un titulo o subtitulo sin dejar espacios con el margen del recuadro. Ejemplo:(T--) Mi titulo o subTitulo");
  }

  // mostra y ocultar lista
  quitar(e: string) {
    this.most = false;
  }

  mostrar(e: string) {
    if (this.most == true)
      this.most = false;
    else
      this.most = true;
  }
  // ---<

  guardar() {
    this.cargado = false;
    if (this.imagen != null) {
      var path = "articulos/" + this.imagen.name + this.imagen.lastModified;
      var path1 = this.selectedArticulo.getImagen();
      this.selectedArticulo.setImagen(path);
      const task = this.servicio.tareaCloudStorage(path, this.imagen);
      this.uploadImage = task.percentageChanges();
      this.uploadImage.subscribe(value => {
        console.log(value);
        this.carga = value;
        delay(30);
      });

      task.then(success => {
        try {
          this.servicio.borrarReferencia(path1);
        } catch (error) {
          console.log(error);
        }
        finally {
          this.cargado = true;
          this.carga = 0;
        }
      });

    }
    if (this.carga == 100)
      this.cargado = true;
    this.selectedArticulo.saveArticulo();
    this.selectedArticulo = new Articulo(this.servicio);
    this.ref = null;
    this.imagen = null;
  }

}
