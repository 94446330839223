import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReCaptcha2Component } from 'ngx-captcha';
import { GeneralService } from 'services/general.service';

@Component({
  selector: 'app-consulta',
  templateUrl: './consulta.component.html',
  styleUrls: ['./consulta.component.scss']
})
export class ConsultaComponent implements OnInit {
  siteKey: String ;
  @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;
    reactiveForm: FormGroup;
    element;nit  
    ruta = "/answer/form/negociar-deuda";
    type: any[] = [
      { value: 'Persona' },
      { value: 'Empresa' },
    ];
    selectedType = 'Quien es';
    selectedConsulta: any;
    selectedPregunta;
    selectedPreguntas: any[];
    selectedConsultas: any[];
    serv: GeneralService;
    preguntas: any[];
    consultasE: any[];
    consultasP: any[];
    cargado= false;
    routes: Router;

    showPersona : "mostrar"| "ocultar" = "ocultar";
    showConsulta : "mostrar"| "ocultar" = "ocultar";

    constructor(serv: GeneralService, routes : Router, private fb: FormBuilder) {
      this.siteKey = "6Lcgqx4aAAAAADwUNZUG8e2ncXfs7XDIERUiEy5P";
      this.reactiveForm = this.fb.group({
        nombre: ["", [Validators.required, Validators.minLength(3)]],
        apellido: ["", [Validators.required, Validators.minLength(3)]],
        correo: ["", [Validators.required, Validators.email]],
        entidad:["", Validators.required],
        consulta: ["" , Validators.required],
        descripcion: ["", [Validators.required]],
        recaptcha: ['' , [Validators.required]]
      });
  
      this.serv = serv;
      this.selectedConsulta = null;
      this.selectedPregunta = '';
      this.selectedPreguntas = [];
      this.selectedConsulta = { consulta: 'Consulta' };
      this.serv.getAllDocuments('t_consultas').subscribe(
        consultas => {
          this.consultasP = [];
          this.consultasE = []; var c = [];
          var c2 = [];
          consultas.forEach((element:any) => {
            c.push(element.consulta);
          });
          c.sort();
          consultas.forEach((element:any) => {
          
            for (let index = 0; index < c.length; index++) {
              if (c[index] == element.consulta)
                c2[index] = element; 
            }
          });
     
          c2.forEach((element:any) => {
            if (element.persona === true)
              this.consultasP.push(element);
            else
              this.consultasE.push(element);
          });
          if (this.selectedType == 'Persona')
            this.selectedConsultas = this.consultasP;
          else
          if (this.selectedType == 'Empresa')
            this.selectedConsultas = this.consultasE;
        });
      this.routes = routes;
    }
    quitar(e:string){
      document.getElementById(e).style.display = "none";
    }
    mostrar(e: string) {
        if(document.getElementById(e).style.display == "flex")
        document.getElementById(e).style.display = "none";
        else
        document.getElementById(e).style.display = "flex";
    }
    prueba(){
      
    }
    comboSelected(a: any, id: String, component: string) {
      switch (id) {
        case ('dropdownPersona'):
          //habilita el dropdown
          this.selectedType = a;
          this.element = document.getElementById('dropdownConsulta');
          this.element.disabled = false;
          // ***
          // carga la lista
          this.reactiveForm.get("entidad").setValue(a);
          if (a == 'Persona'){
            this.selectedConsultas = this.consultasP;
            this.serv.consulta.setType("Persona");

          }
          else{
            this.selectedConsultas = this.consultasE;
            this.serv.consulta.setType("Empresa");
          }
            // **
          //
          this.selectedPregunta = {};
          this.serv.consulta.setConsulta(null);
          this.selectedConsulta = { consulta: 'Consulta' };
          break;
        case ('dropdownConsulta'):
          this.selectedConsulta = a;
          this.selectedPreguntas = [];
          this.selectedPregunta = {};
          this.reactiveForm.get("consulta").setValue(a);
          this.obtenerPreguntas();
          this.ruta = ('index/answer/form/' + this.selectedConsulta.path);
          this.serv.consulta.setConsulta(this.selectedConsulta);
          break;
        case ('dropdownPregunta'):
          break;
      }
      
      this.ruta = ('index/answer/form/' + this.selectedConsulta.path);
      this.serv.consulta.setConsulta(this.selectedConsulta);
      
    }
    redir(){
      this.routes.navigateByUrl(this.ruta);
    }

    obtenerPreguntas() {
      this.serv.getAllDocuments('t_preguntas').subscribe(
        preguntas => {
          preguntas.forEach((pregunta:any) => {
            if (pregunta.consulta === this.selectedConsulta.id && pregunta.estado) {
              this.selectedPreguntas.push(pregunta);
              this.selectedPregunta = pregunta;
              this.serv.consulta.setPregunta(pregunta);
            }
          });
        }
      );
    }
    getError(controlName: string): string {
      //console.log(controlName);
      let error = '';
  
      const control = this.reactiveForm.get(controlName);
      if (control.touched && control.errors != null) {
        error = JSON.stringify(control.errors);
      }
  
      return error;
    }
     validaNumeros(event) {
      if(event.charCode >= 48 && event.charCode <= 57){
        return true;
       }
       return false;        
  }
    validaNumericos(){
      var inputtxt = document.getElementById('t-telefono') as HTMLInputElement; 
      var valor = inputtxt.value;
      for( let i=0;i<valor.length;i++){
          var code=valor.charCodeAt(i);
          if (!(code>=48 && code<=57) ){  
                inputtxt.value=""; 
                return;
              }    
        }
       
     }
    enviarCorreo() {
      console.log(this.reactiveForm.get("consulta").value);
      
      var html = "<h4>Solicitud de contacto </h4>" +
        "<h5 > Datos del cliente </h5>"+
        "<ul >"+
         "<li>"+
         "<p>nombre : "+this.reactiveForm.get("nombre").value+" </p>"+
         "</li>"+
         "<li>"+
         "<p>Apellido: "+this.reactiveForm.get("apellido").value+" </p>"+
         "</li>"+
         "<li>"+
         "<p>Correo Electrónico: "+this.reactiveForm.get("correo").value+" </p>"+
         "</li>"+
         "<li>"+
         "<p>Entidad: "+this.reactiveForm.get("entidad").value+" </p>"+
         "</li>"+
         "<li>"+
         "<p>Asunto: "+this.reactiveForm.get("consulta").value.consulta+" </p>"+
         "</li>"+
         "</ul>"+
         "<br>"+
         "<h5>Comentario del cliente </h5>"+
         "<p> &nbsp; &nbsp; &nbsp; &nbsp;"+this.reactiveForm.get("descripcion").value+"</p>";
  
      var settings = {
        "url": "https://nodemailer-arg2.herokuapp.com/send-email?asd=asd",
        "method": "POST",
        "timeout": 0,
        "data": {
          "email": "gargaglioneabogados@gmail.com",
          "html": html,
          "asunto": this.reactiveForm.get("consulta").value
        }
      };
  
      $.ajax(settings).done(function (response) {
        console.log(response);
      });
      this.captchaElem.resetCaptcha();
    }
    validacionTelefono() {
  
    }
    ngOnInit() {
    }
  }