import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'services/general.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  reactiveForm: FormGroup;

  constructor(private fb: FormBuilder, private router: Router, private servicio: GeneralService) {
    this.reactiveForm = this.fb.group({
      email: ['', [Validators.required]],
      contraseña: ['', [Validators.required]],
    });

  }

  ngOnInit() { }

  login() {
    if (!this.servicio.selectedUser) {
      document.getElementById("loginMain").classList.add("animacionLogin");
      var email = this.reactiveForm.get("email").value;
      var contraseña = this.reactiveForm.get("contraseña").value;
      this.servicio.logear(this.reactiveForm.get("email").value, this.reactiveForm.get("contraseña").value).subscribe((usuarios: any) => {
        if (usuarios.length >= 1) {
          if (usuarios.length > 1) {
            console.log("usuario duplicado o con el mismo email");
          }
          if (usuarios[0].clave === contraseña) {
            this.servicio.selectedUser = usuarios[0];
            this.servicio.error.mensaje = "";
            this.servicio.error.code = -1;
            if (email === "abogadosG") {
              this.router.navigateByUrl("");
            } else {
              this.router.navigateByUrl("index/perfilUsuario");
            }
          }
          else {
            this.servicio.selectedUser = undefined;
            this.reactiveForm.get("email").markAsTouched();
            this.reactiveForm.get("contraseña").setValue(null);
            $("[formControlName='contraseña']").focus();
            this.servicio.error.mensaje = "contraseña incorrecta";
            this.servicio.error.code = 1;
          }
        } else {
          this.reactiveForm.get("email").setValue(null);
          this.reactiveForm.get("email").markAsTouched();
          $("[formControlName='email']").focus();
          this.reactiveForm.get("contraseña").setValue(null);
          this.reactiveForm.get("contraseña").markAsUntouched();
          this.servicio.error.mensaje = "no hay usuario registrado con este email";
          this.servicio.error.code = 1;
        }
        document.getElementById("loginMain").classList.remove("animacionLogin");
        console.log(this.servicio.error);
      });
    } else{
      if (email === "abogadosG") {
        this.router.navigateByUrl("");
      } else {
        this.router.navigateByUrl("index/perfilUsuario");
      }
    }
  }
}
