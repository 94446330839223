import { Component, OnInit } from '@angular/core';
import { AngularFireUploadTask } from '@angular/fire/storage';
import { Caso } from 'app/models/caso';
import { GeneralService } from 'services/general.service';

@Component({
  selector: 'app-casos',
  templateUrl: './casos.component.html',
  styleUrls: ['./casos.component.css']
})
export class CasosComponent implements OnInit {
  selectedConsulta;
  selectedCaso;
  usuarios;
  cargandoCasos = false;
  selectedUser;
  casos = [];
  stop = true;
  constructor(private servicio: GeneralService) {
    this.usuarios = servicio.usuarios;
  }
  guardarNombre(){
    document.getElementById('crCaso').style.display = "flex";
    this.servicio.updateDocument("t_caso", this.selectedCaso.id, this.selectedCaso).then(val => {
      document.getElementById('crCaso').style.display = "none";
    });
  }
  eliminar2(recurso){
    document.getElementById('crCaso').style.display = "flex";
    this.selectedCaso.recursos = this.selectedCaso.recursos.filter(function(rec){
      return rec !== recurso;
    });
    this.servicio.updateDocument("t_caso", this.selectedCaso.id, this.selectedCaso).then(val => {
      document.getElementById('crCaso').style.display = "none";
    });
  }
  eliminar(i) {
    document.getElementById('crCaso').style.display = "flex";
    this.stop = true;
    var caso = this.selectedCaso.hilo as Array<any>;
    this.selectedCaso.hilo = caso.filter(function (e) {
      return e !== i;
    });
    console.log(caso);
    this.servicio.updateDocument("t_caso", this.selectedCaso.id, this.selectedCaso).then(val => {
      document.getElementById("textoNudo").innerHTML = "";
      document.getElementById('crCaso').style.display = "none";
    });
  }
  ngOnInit() {
    document.getElementById('crCaso').style.display = "none";
  }
  setSelect() {
    this.selectedConsulta = -1;
  }
  setCasos() {
    this.selectedCaso = undefined;
    this.cargandoCasos = true;
    this.servicio.getAllDocumentsConditional("t_caso", "cliente", "==", this.selectedUser.usuario_id).subscribe(casos => {
      casos.forEach((caso: any) => {
        if (caso.hilo)
          caso.hilo.forEach((nudo: any) => {
            nudo.fecha = nudo.fecha.toDate() as Date;
          });
      });
      this.casos = casos;
      this.cargandoCasos = false;
    });

  }
  nuevoNudo() {
    document.getElementById('crCaso').style.display = "flex";
    var nudo = {
      fecha: new Date(),
      texto: document.getElementById("textoNudo").innerHTML
    }
    this.stop = false;
    this.selectedCaso.hilo.push(nudo);
    this.servicio.updateDocument("t_caso", this.selectedCaso.id, this.selectedCaso).then(val => {
      document.getElementById("textoNudo").innerHTML = "";
      document.getElementById('crCaso').style.display = "none";
    });
  }
  upFile(e) {
    document.getElementById('crCaso').style.display = "flex";
    var target = ((e as Event).target) as HTMLInputElement;
    var archivo = target.files[0] as File;
    var task: AngularFireUploadTask;
    var archivo1 = {
      tipo: "archivo",
      nombre: "",
      ref: "",
    };
    if (e.target.files && e.target.files[0]) {
      task = this.servicio.tareaCloudStorage("archivos/" + archivo.name, archivo);
      task.task.then(contenido => {
        console.log(contenido.state);
        if (contenido.state == "success") {
          console.log("guardando referencia");
          archivo1.nombre = archivo.name;
          contenido.ref.getDownloadURL().then(reff => {
            archivo1.ref = reff;
            archivo1.nombre = archivo.name;
            this.selectedCaso.recursos.push(archivo1);
            this.servicio.updateDocument("t_caso", this.selectedCaso.id, this.selectedCaso).then(asd => {
              document.getElementById('crCaso').style.display = "none";
            });
          });
        }
      });
    }
  }
  setCaso() {
    this.stop = false;
  }
  scrollBottom() {
    if(!this.stop){
      console.log("asdas");
      setTimeout( function() {$('#historia').scrollTop($('#historia').prop('scrollHeight')); }, 300 );
      this.stop = true;
    }
  }
}
